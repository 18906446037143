@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;

    @media (--viewportLarge) {
        gap: 40px;
        margin: 20px auto;
        max-width: var(--contentMaxWidthPages);
        padding: unset;
    }
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--colorWhite);

    @media (--viewportLarge) {
        padding: 60px;
    }
}

.title {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    padding: 0;

    @media (--viewportLarge) {
        width: 601px;
    }
}

.paragraph {
    width: 100%;
}

.ul {
    width: 100%;
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 24px;

    @media (--viewportLarge) {
        padding-left: unset;
    }
}

.ol {
    width: 100%;
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: 24px;

    @media (--viewportLarge) {
        padding-left: unset;
    }
}

.ctaButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    text-align: center;
    color: var(--colorWhite);
    text-decoration: none;
    height: 48px;
    padding: 12px 24px;
    border-radius: 4px;
    background-color: var(--marketplaceColor);
    transition: var(--transitionStyleButton);
    margin: 24px auto 42px auto;

    &:hover {
        background-color: var(--marketplaceColorDark);
        text-decoration: none;
    }
}

.chevron {
    margin-left: 10px;
}