@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    padding: 24px;
    background-color: var(--colorWhite);

    @media (--viewportLarge) {
        margin: 0 auto;
        padding: 80px 0 0 0;
    }
}

.mainTitle {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    padding: 0;
}

.container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (--viewportLarge) {

        max-width: var(--contentMaxWidthPages);
        flex-direction: row;
        padding: 60px 0;
        gap: 80px;
        justify-content: space-between;
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 24px;
    border-radius: 8px;
    background-color: var(--colorGrey50);
    padding-top: 24px;

    img {
        width: 100%;
    }

    @media (--viewportLarge) {
        gap: 40px;
        width: 50%;
        padding: 40px;
    }
}

.cardTitle {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 0;
}

.info {
    text-align: left;
    margin: 0;
    padding: 0;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: fit-content;
    }
}