@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--colorWhite);
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--viewportLarge) {
        padding: 80px 0;
        gap: 40px;

    }
}

.mainTitle {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    padding: 0;
}

.info {
    margin: 8px 0 0 0;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;

    @media (--viewportLarge) {
        width: 780px;
    }
}

.title {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}

.card {

    p,
    ol,
    li {
        margin: 0;
        padding: 0;
    }

    ol {
        list-style-position: inside;
    }
}