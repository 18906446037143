@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--colorWhite);
    padding: 24px;

    @media (--viewportLarge) {
        padding: unset;
    }
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (--viewportLarge) {
        max-width: var(--contentMaxWidthPages);
        margin: 80px auto;
    }
}

.mainTitle {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    padding: 0;
}

.ul {
    width: 100%;
    padding-left: 24px;

    @media (--viewportLarge) {
        padding-left: unset;
    }
}

.paragraph {
    width: 100%
}