@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--colorWhite);
}

.content {
    width: 100%;
    margin: 24px auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;

    @media (--viewportLarge) {
        max-width: var(--contentMaxWidthPages);
        margin: 80px auto;
    }
}

.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    margin: 0;
    padding: 0;
}

.info {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-align: center;
}

.image {
    width: 100%;

    @media (--viewportLarge) {
        max-width: 640px;
    }
}