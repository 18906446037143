@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    padding: 24px;
    background-color: var(--colorWhite);

    @media (--viewportLarge) {
        padding: 80px 0;
    }
}

.wrapper {
    width: 100%;
    background-color: var(--colorGrey50);
    border-radius: 8px;

    @media (--viewportLarge) {
        padding: 40px 60px;
        max-width: var(--contentMaxWidthPages);
        margin: 0 auto;
    }
}

.mainTitle {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin: 0 0 24px 0;
    padding: 0;

    @media (--viewportLarge) {
        margin: 0 0 40px 0;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (--viewportLarge) {
        flex-direction: row;
        gap: 40px;

    }
}

.image {
    width: 100%;

    @media (--viewportLarge) {
        width: 50%;

    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (--viewportLarge) {
        width: 50%;
    }
}

.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
}

.info {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
}

.icon {
    width: fit-content;
}