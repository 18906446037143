@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;

    @media (--viewportLarge) {
        padding: unset;
        margin: 20px auto 80px auto;
        max-width: var(--contentMaxWidthPages);
    }
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--colorWhite);

    @media (--viewportLarge) {
        flex-direction: row;
        padding: 80px 60px;
        gap: 40px;
    }
}

.image {
    /* width: 50%; */
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* width: 50%; */
    justify-content: center;
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    padding: 0;
    color: var(--marketplaceColor);
}

.info {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    padding: 0;
}

.gold {
    color: var(--colorGold);
    font-weight: 700;
}

.ctaButton {
    width: fit-content;
    text-align: center;
    color: var(--colorWhite);
    text-decoration: none;
    height: 48px;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: var(--marketplaceColor);
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColorDark);
        text-decoration: none;
    }
}

.icon {
    margin-left: 10px;
}

.testimonialsWrapper {
    margin-top: 24px;

    @media (--viewportLarge) {
        margin-top: 40px;
        padding: 0 60px;
    }
}