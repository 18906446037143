@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;

    @media (--viewportLarge) {
        margin: 80px auto 0 auto;
        max-width: var(--contentMaxWidthPages);
        padding: unset;
        gap: 40px;
    }
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--colorWhite);

    @media (--viewportLarge) {
        padding-top: 60px;
    }
}

.title {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    padding: 0;

    @media (--viewportLarge) {
        width: 601px;
    }
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;

    @media (--viewportLarge) {
        gap: 40px;
        padding: 40px 60px 60px 60px;
        flex-direction: row;
        justify-content: space-between;
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;

    @media (--viewportLarge) {
        width: 310px;
    }
}

.icon {
    width: 96px;

    @media (--viewportLarge) {
        width: 128px;
    }
}

.cardTitle {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
}

.description {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 18px;
}

.gold {
    color: var(--colorGold);
    font-weight: 700;
}

.paragraph {
    width: 100%;
}

.ul {
    width: 100%;
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 24px;
    margin-top: 0;

    @media (--viewportLarge) {
        padding-left: unset;
        margin-top: unset;
    }
}

.ol {
    width: 100%;
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: 24px;
    margin-top: 0;

    @media (--viewportLarge) {
        padding-left: unset;
        margin-top: unset;
    }
}

.ctaButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    text-align: center;
    color: var(--colorWhite);
    text-decoration: none;
    height: 48px;
    padding: 12px 24px;
    border-radius: 4px;
    background-color: var(--marketplaceColor);
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColorDark);
        text-decoration: none;
    }
}

.chevron {
    margin-left: 10px;
}