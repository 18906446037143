@import '../../../../styles/customMediaQueries.css';

.root {
    background-color: var(--colorWhite);
    width: 100%;
    padding: 24px;

    @media (--viewportLarge) {
        padding: 0 0 80px 0;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (--viewportLarge) {
        margin: 0 auto;
        max-width: var(--contentMaxWidthPages);
        gap: 40px;
    }
}

.mainTitle {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    padding: 0;
}

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media (--viewportLarge) {
        grid-template-columns: 1fr 1fr 1fr;

    }
}

.card {
    display: flex;
    flex-direction: column;
    gap: 4px;

    img {
        width: 100%;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    background-color: var(--colorGrey50);

    @media (--viewportLarge) {
        padding: 20px 60px;
    }
}

.title {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}

.text {
    margin: 0;
    padding: 0;
    text-align: center;

    @media (--viewportLarge) {
        width: 720px;
    }
}

.ctaButton {
    width: fit-content;
    text-align: center;
    color: var(--colorWhite);
    text-decoration: none;
    height: 48px;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: var(--marketplaceColor);
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColorDark);
        text-decoration: none;
    }
}

.icon {
    margin-left: 10px;
}