@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--colorWhite);
    padding: 24px;

    @media (--viewportLarge) {
        padding: usnet;
    }
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (--viewportLarge) {
        max-width: var(--contentMaxWidthPages);
        margin: 80px auto;

    }
}

.mainTitle {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    padding: 0;
}

.ul {
    width: 100%;
    padding-left: 24px;
    margin-top: 0;

    @media (--viewportLarge) {
        padding-left: unset;
        margin-top: unset;
    }
}

.paragraph {
    width: 100%
}

.ctaButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    height: 48px;
    padding: 12px 24px;
    border-radius: 4px;
    color: var(--colorWhite);
    background-color: var(--marketplaceColor);
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColorDark);
        color: var(--colorWhite);
        text-decoration: none;
    }
}

.chevron {
    margin-left: 10px;
}