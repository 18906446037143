@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--colorWhite);
    padding-bottom: 24px;

    @media (--viewportLarge) {
        padding-bottom: 80px;
    }
}

.header {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 12px;

    p {
        margin: 0;
        padding: 0;
    }

    img {
        width: 100%;

        @media (--viewportLarge) {
            width: fit-content;
        }
    }
}

.rated {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: var(--colorBlack);
}

.trustpilot {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    img {
        margin-top: -5px;
    }
}

.container {
    display: flex;
    flex-direction: row;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;
    gap: 40px;
}

.card {
    text-align: center;
    width: 780px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: rgba(124, 56, 139, 0.1);
}

.title {
    margin: 0;
    padding: 0;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
}