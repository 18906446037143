@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--marketplaceColor);
    min-height: 300px;
    gap: 40px;
    padding: 24px;

    @media (--viewportLarge) {
        padding: 80px 0 40px 0;
        position: relative;
        margin-top: var(--topbarHeightDesktop);
    }

}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: var(--colorWhite);
    margin: 0;
    padding: 0;
}

.info {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--colorWhite);
    margin: 0;
    padding: 0;
    max-width: 720px;
}

.form {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;

    @media (--viewportLarge) {
        width: unset;
    }
}

.rootClassName {
    width: 100%;

    @media (--viewportLarge) {
        width: unset;
    }
}

.location {
    background-color: var(--colorWhite);
    border: 1px solid #d8dce6;
    border-radius: 6px;
    transition: all ease-in-out 100ms;
    outline: none;
    width: 100%;

    &:hover,
    active,
    focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }

    @media (--viewportLarge) {
        width: 438px;
    }
}

.locationInput {
    border: 0 !important;
    box-shadow: none !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 16px;
}

.locationIcon {
    display: flex;
    width: 20px;
    align-self: stretch;
    background-color: var(--colorWhite);
    margin-left: 16px;
}

.button {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;
    color: var(--marketplaceColor);
    padding: 4px 16px;
    background-color: var(--colorWhite);
}

.link {
    color: var(--marketplaceSecondaryColorDark);
    text-decoration: underline;
    transition: all var(--transitionStyleButton);
}

.ctaButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    height: 48px;
    padding: 12px 24px;
    border-radius: 4px;
    color: var(--marketplaceColor);
    background-color: var(--colorWhite);
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColorDark);
        color: var(--colorWhite);
        text-decoration: none;
    }
}

.chevron {
    margin-left: 10px;
}